import env from 'env'
import moment from 'moment'

export const generateAssetURL = ({
  id,
  type,
  size,
  fileName,
  itemName
}: {
  id: string
  type:
    | 'loyalty_card'
    | 'variant'
    | 'modifier'
    | 'image'
    | 'section'
    | 'app_store'
  size: 'thumb' | 'standard' | 'original' | 'cover' | 'logo'
  fileName: string
  itemName: string
}) => {
  const parseError = `https://placehold.co/600x400/random/random?text=${(
    itemName || 'Slerp'
  ).substring(0, 1)}`
  try {
    if (!fileName) {
      return parseError
    } else {
      const imageParts = fileName.split('?')
      const cleanFilename = imageParts[0]
      const extension =
        size === 'original' || size === 'cover'
          ? cleanFilename.split('.').pop()
          : 'jpg'

      const imagePath = `${id}_${imageParts[0]}_${size}.${extension}?v=${
        fileName.split('?')[1] ?? moment().unix()
      }`
      const imageUrl = `https://assets.${env.ASSET_HOST}/uploads/images/${type}/${id}/${imagePath}`

      return imageUrl
    }
  } catch {
    return parseError
  }
}
